import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  List,
  ListItemText,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { addYears, format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { common } from "../messages";
import { BUSINESS_TYPES } from "../utils/constants";
import { parseBmarkenDate } from "../utils/utils";
import { DatePicker } from "./DatePicker";
import NumberInput from "./NumberInput";

const AccessDialog = ({
  //value,
  //type,
  //accesses,
  handleEnable,
  handleDisable,
  businesses,
  reset,
  values,
}) => {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const [value, setValue] = React.useState("lifespan");
  const [lifeSpan, setLifeSpan] = useState(12);
  const [date, setDate] = useState(addYears(new Date(), 1));

  const handleChange = (event) => {
    setValue(event.target.value);
    setLifeSpan(12);
    setDate(addYears(new Date(), 1));
  };

  useEffect(() => {
    setOpen(values.value);
  }, [values]);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={() => {
        setOpen(false);
        reset();
      }}
    >
      {values.type === "access" ? (
        <DialogContent>
          {values.accesses?.map((acc, i) => (
            <React.Fragment key={acc.id}>
              {i !== 0 ? <Divider /> : null}
              <List>
                <Typography variant="h6">
                  {
                    businesses
                      .filter((b) => b.type !== BUSINESS_TYPES.LOOP)
                      .find((b) => b.id === acc.business_id).name
                  }
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={9}>
                    <ListItemText
                      primary={
                        <Typography>
                          L’abilitazione per l’accesso al Centro Commerciale è
                          stata data il
                          {" " +
                            format(
                              parseBmarkenDate(acc.created_at),
                              "dd/MM/yyyy"
                            )}
                          . Per disabilitare l’accesso premi Disabilita
                        </Typography>
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleDisable(acc.id)}
                    >
                      Disabilita
                    </Button>
                  </Grid>
                </Grid>
              </List>
            </React.Fragment>
          ))}
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => {
                setOpen(false);
                reset();
              }}
            >
              {intl.formatMessage(common.close)}
            </Button>
          </DialogActions>
        </DialogContent>
      ) : (
        <DialogContent>
          <FormControl component="fieldset">
            <FormLabel component="legend">Durata</FormLabel>
            <RadioGroup row value={value} onChange={handleChange}>
              <FormControlLabel
                value="lifespan"
                control={<Radio />}
                label="Durata mensile"
              />
              <FormControlLabel
                value="date"
                control={<Radio />}
                label="Data scadenza"
              />
            </RadioGroup>
          </FormControl>
          {value === "lifespan" ? (
            <NumberInput
              name="access_lifespan"
              label="Durata (mesi)"
              value={lifeSpan}
              onChange={(e) => {
                setLifeSpan(e.target.value);
              }}
              fullWidth
              margin="dense"
              required
            />
          ) : (
            <DatePicker
              label="Data di scadenza"
              value={date}
              onChange={(e) => setDate(e)}
              fullWidth
              clearable
              autoOk
              required
              margin="dense"
            />
          )}
          <Typography gutterBottom style={{ marginTop: 20 }}>
            Confermi di voler abilitare l’accesso al Centro Commerciale?
          </Typography>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => {
                setOpen(false);
                reset();
                setLifeSpan(12);
                setDate(addYears(new Date(), 1));
                setValue("lifespan");
              }}
            >
              {intl.formatMessage(common.close)}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                handleEnable(value === "lifespan" ? lifeSpan : date)
              }
            >
              Abilita
            </Button>
          </DialogActions>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default AccessDialog;
