import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  MenuItem,
  Switch,
  TextareaAutosize,
  TextField,
  Typography,
} from "@material-ui/core";
import { useIntl } from "react-intl";
import { common, form } from "../../messages";
import { MANAGER_ROUTES } from "../../utils/constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Title from "../../ui/Title";
import { CONSENT_TYPES } from "../../utils/consentConstants";
import { DatePicker } from "../../ui/DatePicker";
import { Add, Delete } from "@material-ui/icons";
import NumberInput from "../../ui/NumberInput";
import { useBmapi } from "../../utils/bmapi-context";
import { getErrorMessageString } from "../../utils/errors";
import { parseBmarkenDate } from "../../utils/utils";

const getLabel = (type) => {
  const filtered = CONSENT_TYPES.filter((obj) => obj.value === type);
  return filtered && filtered.length ? filtered[0].label : "";
};

export default function ConsentManagementCreate() {
  const intl = useIntl();
  const history = useHistory();
  const {
    bmapi,
    notifyError,
    notifySuccess,
    startLoading,
    stopLoading,
  } = useBmapi();
  const id = new URLSearchParams(location.search).get("id");
  const [eventType, setEventType] = useState(null);
  const [campaignList, setCampaignList] = useState([]);

  const [values, setValues] = useState({
    text: "",
    title: "",
    from: null,
    to: null,
    status: false,
    campaign_id: "-",
    type: "",
    ok_values: [],
    ko_values: [],
    mandatory: false,
    force_signature: false,
    agreement_text: "",
    order: "",
    multi_business: false,
  });

  const loadConsent = useCallback(() => {
    startLoading();
    bmapi
      .getConsent({ consent: id })
      .then((resp) => {
        if (!resp) {
          resp = [];
          setValues(resp);
        } else {
          setValues({
            ...resp[0],
            from: parseBmarkenDate(resp[0].from),
            to: parseBmarkenDate(resp[0].to),
            status: resp[0].status ? false : true,
            campaign_id: resp[0].campaign_id || "-",
            order: resp[0].order || "",
          });
        }
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      })
      .finally(() => {
        stopLoading();
      });
  }, [bmapi, id, intl, notifyError, startLoading, stopLoading]);

  /*useEffect(() => {
    Promise.all([loadCampaigns()]).then(([cmp]) => {
      cmp.unshift({
        name: "Nessuna campagna",
        campaign_id: "-",
        business_name: "",
        isActive: true,
      });
      setCampaignList(cmp);
    });
    // eslint-disable-next-line;
  }, [loadCampaigns]);*/

  useEffect(() => {
    const readCampaigns = async () => {
      let list = await bmapi.getTenantCampaigns();
      list = list.filter(
        (c) =>
          c.type === "CAMPAIGN_MULTIWIN" ||
          c.type === "EARNING_CARD_INSTANT_WIN"
      );
      list.unshift({
        name: "Nessuna campagna",
        id: "-",
        business_name: "",
        isActive: true,
      });
      setCampaignList(list);
    };
    readCampaigns();
  }, [bmapi]);

  useEffect(() => {
    if (!id) {
      const type = new URLSearchParams(location.search)
        .get("subtype")
        .toUpperCase();
      setEventType(type);
    } else {
      loadConsent();
    }
  }, [id, loadConsent]);

  const handleValue = useCallback(
    (varName) => (e) => {
      //setDirty(true);
      if (
        varName === "status" ||
        varName === "mandatory" ||
        varName === "force_signature" ||
        varName === "multi_business"
      ) {
        ((val) => setValues((v) => ({ ...v, [varName]: val })))(
          e.target.checked
        );
      } else if (varName === "from" || varName === "to") {
        ((val) => setValues((v) => ({ ...v, [varName]: val })))(e);
      } else {
        ((val) => setValues((v) => ({ ...v, [varName]: val })))(e.target.value);
      }
    },
    []
  );

  const handleArray = useCallback(
    (varName, index) => (e) => {
      ((val) =>
        setValues((v) => {
          const newVal = [...v[varName]];
          newVal[index] = val;
          return { ...v, [varName]: newVal };
        }))(e.target.value);
    },
    []
  );

  const addElement = useCallback(
    (varName) => () => {
      setValues((v) => {
        let newVal = v[varName] || [];
        newVal = [...newVal, ""];
        return { ...v, [varName]: newVal };
      });
    },
    []
  );

  const removeElement = useCallback(
    (varName, index) => () => {
      setValues((v) => {
        let newVal = [...v[varName]];
        newVal.splice(index, 1);
        return { ...v, [varName]: newVal };
      });
    },
    []
  );

  const confirmEvent = (event) => {
    event.preventDefault();

    let consent = {
      ...values,
      type: eventType || values.type,
      campaign_id: values.campaign_id === "-" ? "" : values.campaign_id,
      order: +values.order,
      status: values.status ? 0 : 1,
    };

    if (!id) {
      startLoading();
      bmapi
        .createConsent(consent)
        .then(() => {
          notifySuccess("Consenso creato con successo");
          history.push(MANAGER_ROUTES.CONSENT_MANAGEMENT);
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        })
        .finally(() => {
          stopLoading();
        });
    } else {
      startLoading();
      bmapi
        .updateConsent(consent)
        .then(() => {
          notifySuccess("Consenso modificato con successo");
          history.push(MANAGER_ROUTES.CONSENT_MANAGEMENT);
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        })
        .finally(() => {
          stopLoading();
        });
    }
  };

  console.log(values.ok_values[0]);

  return (
    <Container maxWidth="sm">
      <Title>
        {id
          ? "Modifica consenso: " + getLabel(values.type)
          : "Creazione consenso: " + getLabel(eventType)}
      </Title>

      <form onSubmit={confirmEvent}>
        <Box mb={2}>
          <Card>
            <CardContent>
              <FormControl fullWidth>
                <TextField
                  required
                  label="Inserire qui il testo"
                  multiline
                  rows={4}
                  variant="outlined"
                  value={values.text}
                  onChange={handleValue("text")}
                  name="text"
                  margin="normal"
                />
                <TextField
                  name="title"
                  label={intl.formatMessage(form.title)}
                  value={values.title}
                  onChange={handleValue("title")}
                  key="title"
                  fullWidth
                  margin="normal"
                  required
                />
                <DatePicker
                  label="Data di inizio"
                  value={values.from}
                  onChange={handleValue("from")}
                  fullWidth
                  margin="normal"
                  //minDate={id ? values.from : addDays(new Date(), 1)}
                  //clearable={!id}
                />
                <DatePicker
                  label="Data di fine"
                  value={values.to}
                  onChange={handleValue("to")}
                  fullWidth
                  margin="normal"
                  minDate={values.from || new Date()}
                  //clearable={!id}
                />
                <TextField
                  name="front_end_type"
                  label="Campagna"
                  value={values.campaign_id}
                  onChange={handleValue("campaign_id")}
                  select
                  fullWidth
                  margin="normal"
                >
                  {campaignList.map((option) => (
                    <MenuItem key={option.name} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  label="Inserire qui il testo del contratto"
                  multiline
                  rows={4}
                  variant="outlined"
                  value={values.agreement_text}
                  onChange={handleValue("agreement_text")}
                  name="agreement_text"
                  margin="normal"
                  required
                />
                <NumberInput
                  name="order"
                  label="Ordine"
                  value={values.order}
                  onChange={handleValue("order")}
                  fullWidth
                  margin="normal"
                  required
                />
              </FormControl>

              <FormControl component="fieldset" margin="normal">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.status}
                        onChange={handleValue("status")}
                        name="status"
                      />
                    }
                    label="Abilitato"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.mandatory}
                        onChange={handleValue("mandatory")}
                        name="mandatory"
                      />
                    }
                    label="Obbligatorio"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.force_signature}
                        onChange={handleValue("force_signature")}
                        name="force_signature"
                      />
                    }
                    label="Obbligo di firma"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.multi_business}
                        onChange={handleValue("multi_business")}
                        name="multi_business"
                      />
                    }
                    label="Multi business"
                  />
                </FormGroup>
              </FormControl>
            </CardContent>
          </Card>
        </Box>

        <Box mb={2}>
          <Card>
            <CardContent style={{ display: "flex" }}>
              <Typography style={{ margin: "12px 2px 0 0" }}>
                Valori consenso confermato *
              </Typography>
              <IconButton onClick={addElement("ok_values")}>
                <Add />
              </IconButton>
            </CardContent>
            {values?.ok_values?.map((v, i) => (
              <List key={"ok_values" + i}>
                <ListItem>
                  <TextareaAutosize
                    style={{ width: "85%" }}
                    name="ok_values"
                    value={v}
                    onChange={handleArray("ok_values", i)}
                    placeholder="Inserire qui il testo"
                  />
                </ListItem>
                <ListItemSecondaryAction>
                  <IconButton onClick={removeElement("ok_values", i)}>
                    <Delete />
                  </IconButton>
                </ListItemSecondaryAction>
              </List>
            ))}
          </Card>
        </Box>

        <Box mb={2}>
          <Card>
            <CardContent style={{ display: "flex" }}>
              <Typography style={{ margin: "12px 2px 0 0" }}>
                Valori consenso revocato *
              </Typography>
              <IconButton onClick={addElement("ko_values")}>
                <Add />
              </IconButton>
            </CardContent>
            {values?.ko_values?.map((v, i) => (
              <List key={"ko_values" + i}>
                <ListItem>
                  <TextareaAutosize
                    style={{ width: "85%" }}
                    name="ko_values"
                    value={v}
                    onChange={handleArray("ko_values", i)}
                    placeholder="Inserire qui il testo"
                  />
                </ListItem>
                <ListItemSecondaryAction>
                  <IconButton onClick={removeElement("ko_values", i)}>
                    <Delete />
                  </IconButton>
                </ListItemSecondaryAction>
              </List>
            ))}
          </Card>
        </Box>

        <CardActions>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!values.ok_values[0] || !values.ko_values[0]}
          >
            {intl.formatMessage(id ? common.update : common.create)}
          </Button>
          <Button
            onClick={() => {
              history.push(MANAGER_ROUTES.CONSENT_MANAGEMENT);
            }}
            variant="contained"
          >
            {intl.formatMessage(common.cancel)}
          </Button>
        </CardActions>
      </form>
    </Container>
  );
}
