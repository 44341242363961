import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Drawer,
  FormControl,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { Add, Create, Delete, FileCopy, MoreVert } from "@material-ui/icons";
import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { common, confirm } from "../../messages";
import { DatePicker } from "../../ui/DatePicker";
import Title from "../../ui/Title";
import FloatingActions, { Action } from "../../ui/FloatingActions";
import { MANAGER_ROUTES } from "../../utils/constants";
import { useHistory } from "react-router-dom";
import { EVENT_TYPES } from "../../utils/eventActionsConstants";
import { useBmapi } from "../../utils/bmapi-context";
import { getErrorMessageString } from "../../utils/errors";
import { format } from "date-fns";
import Confirm from "../../ui/Confirm";

function CreateEventActionButton({ trigger }) {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const create = (subtype) => {
    handleClose();
    history.push(
      MANAGER_ROUTES.EVENT_ACTION_CREATE + "?subtype=" + subtype.toLowerCase()
    );
  };

  return (
    <React.Fragment>
      {trigger(handleClick)}
      <Drawer anchor="bottom" open={Boolean(anchorEl)} onClose={handleClose}>
        <List>
          {EVENT_TYPES.filter((type) => type.value !== "-").map((type) => (
            <MenuItem key={type.value} onClick={() => create(type.value)}>
              <ListItemIcon>{type.icon}</ListItemIcon>
              <ListItemText primary={type.label} />
            </MenuItem>
          ))}
        </List>
      </Drawer>
    </React.Fragment>
  );
}

export default function EventAction() {
  const intl = useIntl();
  const {
    bmapi,
    notifyError,
    notifySuccess,
    startLoading,
    stopLoading,
  } = useBmapi();
  const history = useHistory();
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [selectedType, setSelectedType] = useState("-");
  const [anchorEl, setAnchorEl] = useState(null);
  const [events, setEvents] = useState([]);
  const [error, setError] = useState(false);
  const [eventId, setEventId] = useState(null);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [campaignList, setCampaignList] = useState([]);

  const byDate = (a, b) => new Date(b.end_at) - new Date(a.end_at);

  const getLabel = (type) => {
    const filtered = EVENT_TYPES.filter((obj) => obj.value === type);
    return filtered && filtered.length ? filtered[0].label : "";
  };

  const getCampaignLabel = (id) => {
    const filtered = campaignList.filter((c) => c.id === id);
    return filtered && filtered.length ? filtered[0].name : "Nessuna campagna";
  };

  const loadEventActions = useCallback(() => {
    startLoading();
    if (bmapi) {
      bmapi
        .getEventActions()
        .then((resp) => {
          if (!resp) {
            resp = [];
            setEvents(resp);
          } else {
            setEvents(resp);
          }
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        })
        .finally(() => {
          stopLoading();
        });
    }
  }, [bmapi, intl, notifyError, startLoading, stopLoading]);

  useEffect(() => {
    loadEventActions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadEventActions]);

  useEffect(() => {
    const readCampaigns = async () => {
      let list = await bmapi.getAllTenantCampaigns();
      setCampaignList(list);
    };
    readCampaigns();
  }, [bmapi]);

  const handleOpen = (e, event) => {
    setAnchorEl(e.currentTarget);
    setEventId(event.id);
  };

  const submit = (e) => {
    startLoading();
    e.preventDefault();
    const params = {
      from: from ? format(from, "yyyy-MM-dd") : null,
      to: to ? format(to, "yyyy-MM-dd") : null,
      type: selectedType === "-" ? null : selectedType,
    };

    for (const property in params) {
      if (params[property] === null) {
        delete params[property];
      }
    }

    bmapi
      .getEventActions(params)
      .then((resp) => {
        if (!resp) {
          resp = [];
          setEvents(resp);
        } else {
          setEvents(resp);
        }
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      })
      .finally(() => {
        stopLoading();
      });
  };

  const eventActionDelete = () => {
    startLoading();

    setShowDeleteAlert(false);
    bmapi
      .deleteEventAction(eventId)
      .then(() => {
        setEventId(null);
        notifySuccess("Evento eliminato con successo");
        loadEventActions();
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      })
      .finally(() => {
        stopLoading();
      });
  };

  return (
    <Container maxWidth="sm">
      <Title>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
        >
          <Box>
            <FormattedMessage
              id="navigation.manager.events"
              defaultMessage="Eventi"
            />
          </Box>
        </Box>
      </Title>
      <Box mb={2}>
        <Card>
          <CardContent>
            <form onSubmit={submit}>
              <TextField
                fullWidth
                select
                label="Filtra per tipologia"
                value={selectedType}
                onChange={(e) => setSelectedType(e.target.value)}
              >
                {EVENT_TYPES.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <DatePicker
                label="Filtra per data Da:"
                value={from}
                onChange={setFrom}
                fullWidth
                margin="normal"
                clearable
              />
              <DatePicker
                label="Filtra per data A:"
                value={to}
                onChange={setTo}
                fullWidth
                margin="normal"
                minDate={from}
                clearable
                onError={(e) => (e ? setError(true) : setError(false))}
              />
              <FormControl fullWidth margin="normal">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={error}
                >
                  {intl.formatMessage(common.update)}
                </Button>
              </FormControl>
            </form>
          </CardContent>
        </Card>
      </Box>
      {events.length !== 0 ? (
        <Card>
          <List>
            {events.sort(byDate).map((ev, i) => (
              <React.Fragment key={i}>
                {i !== 0 && <Divider variant="inset" component="li" />}
                <ListItem>
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Typography gutterBottom variant="h6">
                          {ev.title}
                        </Typography>
                        <Typography gutterBottom>
                          Tipo: {getLabel(ev.type)}
                        </Typography>
                        <Typography gutterBottom>
                          Campagna: {getCampaignLabel(ev.campaign_id)}
                        </Typography>
                      </React.Fragment>
                    }
                    secondary={
                      "Dal " +
                      new Date(ev.start_at).toLocaleDateString("it-IT", {
                        day: "numeric",
                        year: "numeric",
                        month: "short",
                      }) +
                      " al " +
                      new Date(ev.end_at).toLocaleDateString("it-IT", {
                        day: "numeric",
                        year: "numeric",
                        month: "short",
                      })
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton onClick={(e) => handleOpen(e, ev)}>
                      <MoreVert />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </React.Fragment>
            ))}
          </List>
        </Card>
      ) : (
        <Card>
          <CardContent style={{ padding: 16 }}>
            Nessun evento presente
          </CardContent>
        </Card>
      )}

      <Menu
        key={eventId}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => {
          setAnchorEl(null);
          setEventId(null);
        }}
        keepMounted
        onClick={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            history.push(MANAGER_ROUTES.EVENT_ACTION_CREATE + "?id=" + eventId);
          }}
        >
          <ListItemIcon>
            <Create />
          </ListItemIcon>
          <Typography variant="inherit">Modifica</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setShowDeleteAlert(true);
          }}
        >
          <ListItemIcon>
            <Delete />
          </ListItemIcon>
          <Typography variant="inherit">Elimina</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            history.push(
              MANAGER_ROUTES.EVENT_ACTION_CREATE + "clone" + "?id=" + eventId
            );
          }}
        >
          <ListItemIcon>
            <FileCopy />
          </ListItemIcon>
          <Typography variant="inherit">Duplica</Typography>
        </MenuItem>
      </Menu>

      <Confirm
        open={showDeleteAlert}
        onConfirm={() => {
          eventActionDelete(eventId);
        }}
        onCancel={() => {
          setEventId(null);
          setShowDeleteAlert(false);
        }}
        text={intl.formatMessage(confirm.deleteElement)}
      />

      <FloatingActions>
        <CreateEventActionButton
          trigger={(onClick) => (
            <Action icon={<Add />} label="Aggiungi evento" action={onClick} />
          )}
        />
      </FloatingActions>
    </Container>
  );
}
