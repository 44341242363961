import React, { useCallback, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { common } from "../messages/common";
import {
  Box,
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  AddCircle,
  CheckCircleOutlined,
  Delete,
  ErrorOutline,
} from "@material-ui/icons";
import { useBmapi } from "../utils/bmapi-context";
import { getErrorMessageString } from "../utils/errors";
import { form, notifications } from "../messages";

const PushNotifications = () => {
  const intl = useIntl();
  const { bmapi, notifyError, startLoading, stopLoading } = useBmapi();
  const [email, setEmail] = useState("");
  const [mailingList, setMailingList] = useState([]);
  const [clients, setClients] = useState([]);
  const [results, setResults] = useState(null);

  const ref = useRef(null);

  const initialValues = {
    client_id: "",
    users: [],
    message: "",
    title: "",
  };

  const [values, setValues] = useState(initialValues);

  const handleValue = useCallback(
    (label) => (e) => {
      ((val) => setValues((v) => ({ ...v, [label]: val })))(e.target.value);
    },
    []
  );

  useEffect(() => {
    startLoading();
    if (bmapi) {
      bmapi
        .getClients()
        .then((resp) => {
          if (!resp) {
            resp = [];
            setClients(resp);
          } else {
            setClients(resp);
          }
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        })
        .finally(() => {
          stopLoading();
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClick = () => {
    if (email) {
      startLoading();
      bmapi
        .getCustomerInfo({ user: email })
        .then((resp) => {
          if (!resp) {
            setValues({
              ...values,
              users: values.users,
            });
            setMailingList(mailingList);
            return;
          }

          if (values.users.find((email) => email === resp.email)) {
            notifyError(intl.formatMessage(notifications.emailAlreadyExists));
            return;
          }

          setValues({
            ...values,
            users: [...values.users, resp.email],
          });
          setMailingList((mailingList) => [...mailingList, resp.email]);
          setEmail("");
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        })
        .finally(() => {
          stopLoading();
        });
    }
  };

  const remove = (i) => {
    const newMailingList = [...values.users];
    newMailingList.splice(i, 1);
    setValues({
      ...values,
      users: newMailingList,
    });
    setMailingList(newMailingList);

    return newMailingList;
  };

  const send = () => {
    startLoading();

    bmapi
      .sendPushNotification(values)
      .then((resp) => {
        if (!resp) {
          resp = {};
          setResults(resp);
        }
        setResults(resp);
      })
      .then(() => {
        const section = ref.current;
        section.scrollIntoView(true);
      })
      .then(() => {
        setValues(initialValues);
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      })
      .finally(() => {
        stopLoading();
      });
  };

  const getResults = (email) => {
    const index = results.users.indexOf(email);
    return results.results[index] === "ok";
  };

  return (
    <React.Fragment>
      <Typography variant="h6">
        {intl.formatMessage(common.pushNotifications)}
      </Typography>
      <TextField
        required
        fullWidth
        select
        label={intl.formatMessage(common.app)}
        value={values.client_id}
        InputLabelProps={{
          shrink: values.client_id,
        }}
        onChange={handleValue("client_id")}
      >
        {clients
          .filter((c) => c.additional_data)
          .map((c) => (
            <MenuItem key={c.id} value={c.id}>
              {c.description}
            </MenuItem>
          ))}
      </TextField>

      <Grid container spacing={1} alignItems="flex-end" justify="space-between">
        <Grid item xs={10} sm={11}>
          <TextField
            margin="normal"
            label={intl.formatMessage(common.addEmail)}
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            InputLabelProps={{
              shrink: email,
            }}
          />
        </Grid>
        <Grid item xs={2} sm={1}>
          <IconButton color="primary" onClick={onClick}>
            <AddCircle />
          </IconButton>
        </Grid>
      </Grid>

      <Box border={1} borderColor="#bdbdbd" borderRadius={3} mt={1}>
        <List>
          <ListItem>
            <ListItemText style={{ color: "#616161" }}>
              {intl.formatMessage(common.addressees)}
            </ListItemText>
          </ListItem>
          {values.users.map((email, i) => (
            <ListItem key={email}>
              <ListItemText primary={email} />
              <ListItemSecondaryAction>
                <IconButton edge="start" size="small" onClick={() => remove(i)}>
                  <Delete />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Box>

      <TextField
        margin="normal"
        required
        label={intl.formatMessage(form.title)}
        fullWidth
        value={values.title}
        onChange={handleValue("title")}
        InputLabelProps={{
          shrink: values.title,
        }}
      />

      <TextField
        label={intl.formatMessage(common.message)}
        multiline
        rows={4}
        variant="outlined"
        fullWidth
        margin="normal"
        value={values.message}
        onChange={handleValue("message")}
        required
      />
      <Button
        variant="contained"
        color="primary"
        onClick={send}
        disabled={
          !values.client_id ||
          !values.message ||
          !values.title ||
          values.users.length === 0
        }
      >
        {intl.formatMessage(common.send)}
      </Button>

      {results && (
        <List ref={ref}>
          {mailingList.map((email) => (
            <ListItem key={email}>
              <ListItemText primary={email} />
              <ListItemSecondaryAction>
                <IconButton edge="start" size="small">
                  {getResults(email) ? (
                    <CheckCircleOutlined />
                  ) : (
                    <ErrorOutline />
                  )}
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      )}
    </React.Fragment>
  );
};

export default PushNotifications;
