import React from "react";
import {
  Done,
  Label,
  Grade,
  LocalActivity,
  CheckCircle,
  CardMembership,
  EmojiEvents,
  CheckBoxOutlineBlank,
  CropFree,
  Loop,
  Class,
  Eco,
} from "@material-ui/icons";

export const EVENT_TYPES = [
  { value: "-", label: "Nessun tipo" },
  {
    value: "CHECKIN_EVENT_ACTION",
    label: "Checkin",
    icon: <CheckCircle />,
    show_campaign: true,
  },
  { value: "PRE_CHECKIN_EVENT_ACTION", label: "Pre checkin", icon: <Done /> },
  {
    value: "BONUS_POINTS_EVENT_ACTION",
    label: "Bonus punti",
    icon: <EmojiEvents />,
  },
  {
    value: "BONUS_PLAY_EVENT_ACTION",
    label: "Bonus giocata",
    icon: <LocalActivity />,
  },
  {
    value: "MULTIPLY_PLAY_EVENT_ACTION",
    label: "Giocata multipla",
    icon: <Loop />,
    show_campaign: true,
  },
  {
    value: "COMPOSITE_PLAY_EVENT_ACTION_TYPE_MULTI_STAMPS",
    label: "Giocata composita: multi-cartolina",
    icon: <CardMembership />,
    show_campaign: true,
  },
  {
    value: "COMPOSITE_PLAY_EVENT_ACTION_TYPE_MULTI_COUPONS",
    label: "Giocata composita: multi-bollino",
    icon: <CheckBoxOutlineBlank />,
    show_campaign: true,
  },
  {
    value: "BONUS_EASTER_EVENT_ACTION",
    label: "Bonus Pasqua",
    icon: <Grade />,
  },
  {
    value: "BONUS_CATEGORY_EVENT_ACTION",
    label: "Bonus categorie merceologiche",
    icon: <Class />,
    show_campaign: true,
  },
  { value: "BANNER_EVENT_ACTION", label: "Banner", icon: <Label /> },
  {
    value: "SCANNER_MESSAGE_EVENT_ACTION",
    label: "Scanner",
    icon: <CropFree />,
  },
  {
    value: "GREEN_EVENT_ACTION",
    label: "Giocata green",
    icon: <Eco />,
  },
];
