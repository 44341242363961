import React from "react";
import { useBmapi } from "../../utils/bmapi-context";
import { TENANTS } from "../../utils/constants";
import Products from "./Products";
//import Pricing from "../../components/Pricing";
import Subscriptions from "./Subscriptions";
import Content from "../Content";
import Pricing from "../../components/Pricing";
//import ConsumerSubscriptions from "./ConsumerSubscriptions";

export default function Home() {
  const { bmapi, userId } = useBmapi();

  /*
  if (bmapi.tenant === TENANTS.SELF) {
    return userId ? (
      <>
        <Subscriptions />
        <ConsumerSubscriptions />
      </>
    ) : (
      <Pricing />
    );
  }
  

  console.log("....home consumer");

  if (bmapi.tenant === TENANTS.SELF) {
    return userId ? (
      <>
        <Content content="home" />
        <Subscriptions />
      </>
    ) : (
      <Content content="home" />
    );
  }

  return userId ? <Products /> : <Content content="home" />;
  */
  console.log(".............mmmmmm", bmapi.tenant);
  if (bmapi.tenant === TENANTS.SELF) {
    return userId ? <Subscriptions /> : <Pricing />;
  }

  return userId ? <Products /> : <Content content="home" />;
}
