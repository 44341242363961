import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Switch,
  TextField,
} from "@material-ui/core";
import { useIntl } from "react-intl";
import { common, form } from "../../messages";
import { MANAGER_ROUTES } from "../../utils/constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Title from "../../ui/Title";
import { EVENT_TYPES } from "../../utils/eventActionsConstants";
import { useBmapi } from "../../utils/bmapi-context";
import { format } from "date-fns";
import { getErrorMessageString } from "../../utils/errors";
import { parseBmarkenDate } from "../../utils/utils";
import { DatePicker, TimePicker } from "../../ui/DatePicker";
import NumberInput from "../../ui/NumberInput";
import CheckInExtraction from "../../components/CheckInExtraction";

const getLabel = (type) => {
  const filtered = EVENT_TYPES.filter((obj) => obj.value === type);
  return filtered && filtered.length ? filtered[0].label : "";
};

export default function EventActionCreate() {
  const intl = useIntl();
  const history = useHistory();
  const {
    bmapi,
    notifyError,
    notifySuccess,
    startLoading,
    stopLoading,
  } = useBmapi();
  const bsId = bmapi.getUserInfo().business.id;
  const id = new URLSearchParams(location.search).get("id");
  const clone = location.href.includes("clone");

  const [eventType, setEventType] = useState(null);
  const [campaignList, setCampaignList] = useState([]);

  const [values, setValues] = useState({
    type: "",
    title: "",
    start_at: null,
    end_at: null,
    message: "",
    alt_message: "",
    fe: false,
    label: "",
    status: false,
    url: "",
    value: "",
    test: false,
    campaign_id: "-",
    dir_marketing_required: false,
    business_id: bsId,
  });

  const showCampaign = () => {
    const eventsList = EVENT_TYPES.filter((e) => e.show_campaign);
    return eventsList.map((e) => e.value).includes(values.type || eventType);
  };

  const getTitle = () => {
    return id && clone
      ? `Duplica evento: ${getLabel(values.type)}`
      : id
      ? `Modifica evento: ${getLabel(values.type)}`
      : `Creazione evento: ${getLabel(eventType)}`;
  };

  const loadEventAction = useCallback(() => {
    startLoading();
    bmapi
      .getEventActionById({ business: bsId, event: id })
      .then((resp) => {
        if (!resp) {
          resp = [];
          setValues(resp);
        } else {
          setValues({
            ...resp,
            campaign_id: resp.campaign_id || "-",
            start_at: parseBmarkenDate(resp.start_at),
            end_at: parseBmarkenDate(resp.end_at),
            start_hour: parseBmarkenDate(resp.start_at),
            end_hour: parseBmarkenDate(resp.end_at),
            value: resp.value || "",
            status: resp.status ? false : true,
          });
        }
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      })
      .finally(() => {
        stopLoading();
      });
  }, [bmapi, bsId, id, intl, notifyError, startLoading, stopLoading]);

  useEffect(() => {
    const readCampaigns = async () => {
      let list = await bmapi.getAllTenantCampaigns();
      list = list.filter(
        (c) =>
          c.type === "CAMPAIGN_MULTIWIN" ||
          c.type === "EARNING_CARD_INSTANT_WIN"
      );
      list.unshift({
        name: "Nessuna campagna",
        id: "-",
        business_name: "",
        isActive: true,
      });
      setCampaignList(list);
    };
    readCampaigns();
  }, [bmapi]);

  useEffect(() => {
    if (!id) {
      const type = new URLSearchParams(location.search)
        .get("subtype")
        .toUpperCase();
      setEventType(type);
    } else {
      loadEventAction();
    }
  }, [id, loadEventAction]);

  const handleValue = useCallback(
    (varName) => (e) => {
      //setDirty(true);
      if (
        varName === "fe" ||
        varName === "test" ||
        varName === "status" ||
        varName === "dir_marketing_required"
      ) {
        ((val) => setValues((v) => ({ ...v, [varName]: val })))(
          e.target.checked
        );
      } else if (
        varName === "start_at" ||
        varName === "end_at" ||
        varName === "start_hour" ||
        varName === "end_hour"
      ) {
        ((val) => setValues((v) => ({ ...v, [varName]: val })))(e);
      } else {
        ((val) => setValues((v) => ({ ...v, [varName]: val })))(e.target.value);
      }
    },
    []
  );

  /*const confirmEvent = (event) => {
    event.preventDefault();
    const startDate =
      format(values.start_at, "yyyy-MM-dd") +
      "T" +
      format(values.start_hour, "HH:mm:ss");
    const endDate =
      format(values.end_at, "yyyy-MM-dd") +
      "T" +
      format(values.end_hour, "HH:mm:ss");
    let eAction = {
      ...values,
      type: eventType || values.type,
      campaign_id: values.campaign_id === "-" ? "" : values.campaign_id,
      status: values.status ? 0 : 1,
      value: +values.value || 0,
      start_at: new Date(startDate),
      end_at: new Date(endDate),
    };
    if (!id) {
      startLoading();
      bmapi
        .createEventAction(eAction)
        .then(() => {
          notifySuccess("Evento creato con successo");
          history.push(MANAGER_ROUTES.EVENT_ACTION);
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        })
        .finally(() => {
          stopLoading();
        });
    } else if (id && clone) {
      const excludedKeys = {
        created_at: true,
        deleted_at: true,
        updated_at: true,
        tenant_id: true,
        id: true,
      };

      const eActionClone = {};
      for (const key in eAction) {
        if (!(key in excludedKeys)) {
          eActionClone[key] = eAction[key];
        }
      }
      startLoading();
      bmapi
        .createEventAction(eActionClone)
        .then(() => {
          notifySuccess("Evento duplicato con successo");
          history.push(MANAGER_ROUTES.EVENT_ACTION);
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        })
        .finally(() => {
          stopLoading();
        });
    } else {
      startLoading();
      bmapi
        .updateEventAction(eAction)
        .then(() => {
          notifySuccess("Evento modificato con successo");
          history.push(MANAGER_ROUTES.EVENT_ACTION);
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        })
        .finally(() => {
          stopLoading();
        });
    }
  };*/

  const confirmEvent = async (event) => {
    event.preventDefault();

    const {
      start_at,
      start_hour,
      end_at,
      end_hour,
      campaign_id,
      status,
      value,
      type,
    } = values;

    const startDate = `${format(start_at, "yyyy-MM-dd")}T${format(
      start_hour,
      "HH:mm:ss"
    )}`;
    const endDate = `${format(end_at, "yyyy-MM-dd")}T${format(
      end_hour,
      "HH:mm:ss"
    )}`;

    const eAction = {
      ...values,
      type: eventType || type,
      campaign_id: campaign_id === "-" ? "" : campaign_id,
      status: status ? 0 : 1,
      value: +value || 0,
      start_at: new Date(startDate),
      end_at: new Date(endDate),
    };

    const excludedKeys = {
      created_at: true,
      deleted_at: true,
      updated_at: true,
      tenant_id: true,
      id: true,
    };

    const eActionClone = Object.keys(eAction)
      .filter((key) => !excludedKeys[key])
      .reduce((obj, key) => {
        obj[key] = eAction[key];
        return obj;
      }, {});

    try {
      startLoading();

      if (!id) {
        await bmapi.createEventAction(eAction);
        notifySuccess("Evento creato con successo");
      } else if (id && clone) {
        await bmapi.createEventAction(eActionClone);
        notifySuccess("Evento duplicato con successo");
      } else {
        await bmapi.updateEventAction(eAction);
        notifySuccess("Evento modificato con successo");
      }

      history.push(MANAGER_ROUTES.EVENT_ACTION);
    } catch (e) {
      notifyError(getErrorMessageString(e, intl));
    } finally {
      stopLoading();
    }
  };

  return (
    <Container maxWidth="sm">
      <Title>{getTitle()}</Title>

      <form onSubmit={confirmEvent}>
        <Box mb={2}>
          <Card>
            <CardContent>
              <FormControl fullWidth>
                {showCampaign() && (
                  <TextField
                    name="front_end_type"
                    label="Campagna"
                    value={
                      eventType === "MULTIPLY_PLAY_EVENT_ACTION"
                        ? ""
                        : values.campaign_id
                    }
                    onChange={handleValue("campaign_id")}
                    select
                    fullWidth
                    required
                    margin="normal"
                  >
                    {eventType === "MULTIPLY_PLAY_EVENT_ACTION" ||
                    values.type === "MULTIPLY_PLAY_EVENT_ACTION"
                      ? campaignList
                          .filter((c) => c.id !== "-")
                          .map((option) => (
                            <MenuItem key={option.name} value={option.id}>
                              {option.name}
                            </MenuItem>
                          ))
                      : campaignList.map((option) => (
                          <MenuItem key={option.name} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                  </TextField>
                )}
                <TextField
                  name="title"
                  label={intl.formatMessage(form.title)}
                  value={values.title}
                  onChange={handleValue("title")}
                  key="title"
                  fullWidth
                  margin="normal"
                  required
                />
                <DatePicker
                  label="Data di inizio"
                  value={values.start_at}
                  onChange={handleValue("start_at")}
                  fullWidth
                  margin="normal"
                  required
                  //minDate={id ? values.start_at : null}
                  //clearable={!id}
                  clearable
                />
                <TimePicker
                  label="Ora di inizio"
                  value={values.start_hour || null}
                  onChange={handleValue("start_hour")}
                  fullWidth
                  margin="normal"
                  required
                  clearable
                />
                <DatePicker
                  label="Data di fine"
                  value={values.end_at}
                  onChange={handleValue("end_at")}
                  fullWidth
                  margin="normal"
                  required
                  minDate={values.start_at}
                  clearable
                />
                <TimePicker
                  label="Ora di fine"
                  value={values.end_hour || null}
                  onChange={handleValue("end_hour")}
                  fullWidth
                  margin="normal"
                  required
                  clearable
                />
                <TextField
                  label="Inserire qui il messaggio"
                  multiline
                  rows={4}
                  variant="outlined"
                  value={values.message}
                  onChange={handleValue("message")}
                  name="message"
                  margin="normal"
                />
                <TextField
                  label="Inserire qui il messaggio alternativo"
                  multiline
                  rows={4}
                  variant="outlined"
                  value={values.alt_message}
                  onChange={handleValue("alt_message")}
                  name="alt_message"
                  margin="normal"
                />
                <TextField
                  name="label"
                  label="Etichetta pulsante azione"
                  value={values.label}
                  onChange={handleValue("label")}
                  key="label"
                  fullWidth
                  margin="normal"
                />
                <TextField
                  name="url"
                  label="Url"
                  value={values.url}
                  onChange={handleValue("url")}
                  key="url"
                  fullWidth
                  margin="normal"
                />
                <NumberInput
                  name="value"
                  label="Valore"
                  value={values.value}
                  onChange={handleValue("value")}
                  fullWidth
                  margin="normal"
                />
              </FormControl>

              <FormControl component="fieldset" margin="normal">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.fe}
                        onChange={handleValue("fe")}
                        name="fe"
                      />
                    }
                    label="Visibile su front-end"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.status}
                        onChange={handleValue("status")}
                        name="status"
                      />
                    }
                    label="Abilitato"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.test}
                        onChange={handleValue("test")}
                        name="test"
                      />
                    }
                    label="Test"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.dir_marketing_required}
                        onChange={handleValue("dir_marketing_required")}
                        name="dir_marketing_required"
                      />
                    }
                    label="Visibile solo con marketing diretto"
                  />
                </FormGroup>
              </FormControl>
              {id && values.type === "CHECKIN_EVENT_ACTION" && (
                <CheckInExtraction id={id} />
              )}
            </CardContent>
          </Card>
        </Box>

        <CardActions>
          <Button type="submit" variant="contained" color="primary">
            {intl.formatMessage(
              id && clone ? common.create : id ? common.update : common.create
            )}
          </Button>
          <Button
            onClick={() => {
              history.push(MANAGER_ROUTES.EVENT_ACTION);
            }}
            variant="contained"
          >
            {intl.formatMessage(common.cancel)}
          </Button>
        </CardActions>
      </form>
    </Container>
  );
}
