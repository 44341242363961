import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { addWeeks, format } from "date-fns";

import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";

import { common, form, navigation } from "../../messages";
import { DatePicker } from "../../ui/DatePicker";
import Title from "../../ui/Title";
import { useBmapi } from "../../utils/bmapi-context";
import { ecommerce } from "../../messages/ecommerce";
import { ORDER_STATUS } from "../../utils/ecommerceConstants";
import { getErrorMessageString } from "../../utils/errors";
import { parseBmarkenDate } from "../../utils/utils";
import { BUSINESS_TYPES } from "../../utils/constants";

const byDate = (a, b) => {
  if (
    a.planned_delivery.substring(0, 10) === b.planned_delivery.substring(0, 10)
  ) {
    const timeA =
      a.slot_id.slice(-4).substring(0, 2) +
      ":" +
      a.slot_id.slice(-4).substring(2, 4);
    const timeB =
      b.slot_id.slice(-4).substring(0, 2) +
      ":" +
      b.slot_id.slice(-4).substring(2, 4);
    return timeA > timeB;
  } else return new Date(b.planned_delivery) - new Date(a.planned_delivery);
};

function Report({ orders, businesses, filter }) {
  const intl = useIntl();

  const businessId = [...new Set(orders?.map((o) => o.business_id))];

  const getLabel = useCallback(
    (code) => {
      const filtered = ORDER_STATUS.filter((obj) => obj.value === code);
      return filtered && filtered.length && filtered[0].value !== 0
        ? intl.formatMessage(ecommerce[filtered[0].label])
        : "Tutti gli stati";
    },
    [intl]
  );

  return (
    <React.Fragment>
      {orders &&
        businessId.map((id) => (
          <Box mb={2} key={id}>
            <Card>
              <CardContent>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="h6">
                    {businesses.find((b) => b.id === id).name}
                  </Typography>
                </Box>
              </CardContent>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Id ordine</TableCell>
                      <TableCell>Data e ora</TableCell>
                      <TableCell align="right">Totale</TableCell>
                      <TableCell align="right">Stato</TableCell>
                    </TableRow>
                  </TableHead>
                  {orders
                    .filter((o) => o.business_id === id)
                    .filter((ord) => (filter ? ord.status === filter : ord))
                    .sort(byDate)
                    .map((o) => (
                      <TableBody key={o}>
                        <TableRow>
                          <TableCell>{o.id.substr(-5, 5)}</TableCell>
                          <TableCell>
                            {format(
                              parseBmarkenDate(o.planned_delivery),
                              "dd/MM/yy"
                            ) +
                              " " +
                              o.slot_id.slice(-4).substring(0, 2) +
                              ":" +
                              o.slot_id.slice(-4).substring(2, 4)}
                          </TableCell>
                          <TableCell align="right">
                            {intl.formatNumber(o.amount / 100, {
                              style: "currency",
                              currency: "EUR",
                            })}
                          </TableCell>
                          <TableCell align="right">
                            {getLabel(o.status)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
                </Table>
              </TableContainer>
            </Card>
          </Box>
        ))}
    </React.Fragment>
  );
}

export default function ReportingOrders() {
  const {
    bmapi,
    notifyError,
    startLoading,
    stopLoading,
    businesses,
  } = useBmapi();
  const intl = useIntl();
  const [orderList, setOrderList] = useState(false);
  const [error, setError] = useState(false);
  const [from, setFrom] = useState(addWeeks(new Date(), -1));
  const [to, setTo] = useState(new Date());
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filter, setFilter] = useState(null);

  const selectedStoreId = bmapi.getUserInfo().business.id;

  const bsLoop = () => {
    return businesses
      .filter((b) => b.type === BUSINESS_TYPES.LOOP)
      .find((b) => b.id === selectedStoreId);
  };

  const getLabel = useCallback(
    (code) => {
      const filtered = ORDER_STATUS.filter((obj) => obj.value === code);
      return filtered && filtered.length && filtered[0].value !== 0
        ? intl.formatMessage(ecommerce[filtered[0].label])
        : "Tutti gli stati";
    },
    [intl]
  );

  const removeFromArray = (params, date) => {
    const index = params.indexOf(date);
    if (index > -1) {
      const newParams = [...params];
      newParams.splice(index, 1);
      params = newParams;
    }
    return params;
  };

  const update = useCallback(() => {
    startLoading();
    setOrderList(false);
    setFilter(null);

    let multi = true;

    let params = [
      ["business", bmapi.getUserInfo().business.id],
      from ? ["from", format(from, "dd-MM-yyyy")] : null,
      to ? ["to", format(to, "dd-MM-yyyy")] : null,
      ["status", 3],
      ["status", 4],
      ["status", 5],
    ];

    if (!from) {
      params = removeFromArray(params, from);
    }
    if (!to) {
      params = removeFromArray(params, to);
    }

    bmapi
      .getEOrder(params, multi)
      .then((orders) => {
        if (!orders) {
          orders = [];
          setOrderList(orders);
        } else {
          setOrderList(orders.filter((o) => o.id));
        }
      })
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(stopLoading);
  }, [bmapi, intl, notifyError, from, to, startLoading, stopLoading]);

  const submit = (e) => {
    e.preventDefault();
    update();
  };

  useEffect(() => {
    update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container maxWidth="sm">
      <Title>{intl.formatMessage(navigation.reportingOrders)}</Title>
      <Box mb={2}>
        <Card>
          <CardContent>
            <form onSubmit={submit}>
              <TextField
                value={
                  bsLoop()
                    ? "Tutti i negozi"
                    : businesses.find((b) => b.id === selectedStoreId).name
                }
                fullWidth
                margin="normal"
                disabled
              />
              <DatePicker
                label={intl.formatMessage(common.from)}
                value={from}
                onChange={setFrom}
                fullWidth
                margin="normal"
                required
                clearable
                maxDate={new Date()}
              />
              <DatePicker
                label={intl.formatMessage(common.to)}
                value={to}
                onChange={setTo}
                fullWidth
                margin="normal"
                required
                minDate={from}
                maxDate={new Date()}
                onError={(e) => (e ? setError(true) : setError(false))}
              />
              <FormControl fullWidth margin="normal">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={error}
                >
                  {intl.formatMessage(common.update)}
                </Button>
              </FormControl>
            </form>
          </CardContent>
        </Card>
      </Box>
      {orderList.length ? (
        <Box mb={3}>
          <Title>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flexWrap="wrap"
            >
              <Typography variant="h6">Ordini</Typography>
              {orderList.length > 0 && (
                <Box display="flex" flexDirection="column">
                  <Button
                    style={{ padding: 0, fontSize: "16px" }}
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                  >
                    <FormattedMessage
                      id="common.filter"
                      defaultMessage="Filtro"
                    />
                  </Button>
                  <Typography variant="caption">{getLabel(filter)}</Typography>
                </Box>
              )}
            </Box>
            <Menu
              anchorEl={anchorEl}
              keepMounted={false}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem
                onClick={() => {
                  setFilter(0);
                  setAnchorEl(null);
                }}
              >
                Tutti gli stati
              </MenuItem>
              {ORDER_STATUS.filter(
                (o) => o.value === 3 || o.value === 4 || o.value === 5
              ).map((option) => (
                <MenuItem
                  key={option.value}
                  onClick={() => {
                    setFilter(option.value);
                    setAnchorEl(null);
                  }}
                >
                  {intl.formatMessage(ecommerce[option.label])}
                </MenuItem>
              ))}
            </Menu>
          </Title>
          <Report orders={orderList} businesses={businesses} filter={filter} />
        </Box>
      ) : (
        <Card>
          <CardContent>Nessun ordine da mostrare</CardContent>
        </Card>
      )}
    </Container>
  );
}
