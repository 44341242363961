import React from "react";
import {
  Assignment,
  AssignmentInd,
  SettingsApplications,
  Person,
  VerifiedUser,
} from "@material-ui/icons";

export const CONSENT_TYPES = [
  { value: "-", label: "Nessun tipo" },
  {
    value: "CAMPAIGN-RULES",
    label: "Regolamento campagna",
    icon: <Assignment />,
  },
  {
    value: "DIR-MARKETING",
    label: "Marketing diretto",
    icon: <AssignmentInd />,
  },
  {
    value: "SERVICE-RULES",
    label: "Termini di servizio",
    icon: <SettingsApplications />,
  },
  {
    value: "PRIVACY",
    label: "Privacy",
    icon: <VerifiedUser />,
  },
  {
    value: "PROFILING",
    label: "Profilazione",
    icon: <Person />,
  },
];
