import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  Divider,
  Fab,
  FormControl,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  //useMediaQuery,
} from "@material-ui/core";
import Title from "../../ui/Title";
import { parseBmarkenDate } from "../../utils/utils";
import { addDays, addMonths, format } from "date-fns";
import FloatingActions from "../../ui/FloatingActions";
import QrReader from "react-qr-reader";
import { CenterFocusStrong, Error, HighlightOff } from "@material-ui/icons";
import { account, common, confirm } from "../../messages";
import { useIntl } from "react-intl";
import { useBmapi } from "../../utils/bmapi-context";
import AccessDialog from "../../ui/AccessDialog";
import Confirm from "../../ui/Confirm";
import { BUSINESS_TYPES, ROLES } from "../../utils/constants";
import { getErrorMessageString } from "../../utils/errors";

const byDate = (a, b) => {
  return new Date(a.expiring_at) - new Date(b.expiring_at);
};

const warningExpDate = (date) => {
  if (parseBmarkenDate(date) === null) return false;
  const warning = format(addDays(parseBmarkenDate(date), -10), "yyyy-MM-dd");
  const today = format(new Date(), "yyyy-MM-dd");
  return today > warning;
};

const checkExpDate = (date) => {
  if (parseBmarkenDate(date) === null) return false;
  const expired = format(parseBmarkenDate(date), "yyyy-MM-dd");
  const today = format(new Date(), "yyyy-MM-dd");
  return today > expired;
};

const getDaysLeft = (date) => {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  return Math.round(Math.abs((parseBmarkenDate(date) - new Date()) / oneDay));
};

function Warning({ exp }) {
  const breakPoints = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  return (
    <Tooltip
      disableFocusListener
      title={
        checkExpDate(exp)
          ? "Attenzione! L'autorizzazione è scaduta."
          : `Attenzione! Mancano ${getDaysLeft(
              exp
            )} giorni alla scadenza dell'autorizzazione.`
      }
    >
      <IconButton
        style={{
          color: checkExpDate(exp) ? "red" : "#ffb600",
          backgroundColor: "transparent",
          padding: breakPoints ? 12 : 0,
        }}
      >
        <Error />
      </IconButton>
    </Tooltip>
  );
}

function Report({ registrations, businesses, handleDisable }) {
  const intl = useIntl();
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [regIdToDelete, setRegIdToDelete] = useState(false);

  const businessId = [...new Set(registrations?.map((r) => r.business_id))];

  return (
    <React.Fragment>
      {registrations &&
        businessId.map((id) => (
          <Box mb={2} key={id}>
            <Card>
              <CardContent>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="h6">
                    {businesses.find((b) => b.id === id).name}
                  </Typography>
                </Box>
              </CardContent>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>Data autor.</TableCell>
                      <TableCell>Data scad.</TableCell>
                      <TableCell>Nome e cognome</TableCell>
                      <TableCell>Email</TableCell>
                    </TableRow>
                  </TableHead>
                  {registrations
                    .filter((r) => r.business_id === id)
                    .sort(byDate)
                    .map((r) => (
                      <TableBody key={r}>
                        <TableRow>
                          <TableCell style={{ display: "flex" }}>
                            <IconButton
                              edge="end"
                              onClick={() => {
                                setShowDeleteAlert(true);
                                setRegIdToDelete(r.id);
                              }}
                            >
                              <HighlightOff />
                            </IconButton>
                            {warningExpDate(r.expiring_at) && (
                              <Warning exp={r.expiring_at} />
                            )}
                          </TableCell>
                          <TableCell>
                            {format(parseBmarkenDate(r.created_at), "dd/MM/yy")}
                          </TableCell>
                          <TableCell>
                            {!parseBmarkenDate(r.expiring_at)
                              ? "Nessuna scadenza"
                              : format(
                                  parseBmarkenDate(r.expiring_at),
                                  "dd/MM/yy"
                                )}
                          </TableCell>
                          <TableCell>
                            {r.first_name + " " + r.last_name}
                          </TableCell>
                          <TableCell>{r.email}</TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
                </Table>
              </TableContainer>
            </Card>
          </Box>
        ))}
      <Confirm
        open={showDeleteAlert}
        onConfirm={() => {
          handleDisable(regIdToDelete);
          setShowDeleteAlert(false);
          setRegIdToDelete(false);
        }}
        onCancel={() => {
          setShowDeleteAlert(false);
          setRegIdToDelete(false);
        }}
        text={intl.formatMessage(confirm.deleteElement)}
      />
    </React.Fragment>
  );
}

export default function EmployeeAccess() {
  const intl = useIntl();
  const {
    notifyError,
    bmapi,
    businesses,
    notifySuccess,
    startLoading,
    stopLoading,
  } = useBmapi();
  const [open, setOpen] = useState(false);
  const initialValues = {
    value: false,
    type: "",
    accesses: [],
  };
  const [values, setValues] = useState(initialValues);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [regIdToDelete, setRegIdToDelete] = useState(false);
  const [registrations, setRegistrations] = useState([]);
  const [storedCode, setStoredCode] = useState(null);
  const [email, setEmail] = useState(null);
  const isTenantManager = bmapi.getUserInfo().role === ROLES.TENANT_MANAGER;
  const selectedStoreId = bmapi.getUserInfo().business.id;

  const bsLoop = () => {
    return businesses
      .filter((b) => b.type === BUSINESS_TYPES.LOOP)
      .find((b) => b.id === selectedStoreId);
  };

  const reset = () => {
    setStoredCode(null);
    setValues(initialValues);
  };

  const byEmail = useCallback(
    (user) => {
      return email ? user.email === email : user;
    },
    [email]
  );

  const loadRegistrations = useCallback(() => {
    startLoading();
    bmapi
      .getRegistrations({ business: selectedStoreId, type: "access" })
      .then((resp) => {
        if (!resp) {
          resp = [];
          setRegistrations(resp);
        } else {
          setRegistrations(resp.filter(byEmail));
        }
      })
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(stopLoading);
  }, [
    bmapi,
    intl,
    notifyError,
    startLoading,
    stopLoading,
    selectedStoreId,
    byEmail,
  ]);

  useEffect(() => {
    loadRegistrations();
    // disable loading when typing email address
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleError = (err) => {
    notifyError(err || "Si è verificato un errore");
    setStoredCode(null);
  };

  const handleScan = (code) => {
    if (code && code !== storedCode) {
      setStoredCode(code);
      const qrCode = { qrcode: code };
      bmapi
        .accessRegistration(qrCode, selectedStoreId)
        // apiPost -> http Code 200 (then)
        .then((resp) => {
          setValues({ ...values, accesses: resp, value: true, type: "access" });
        })
        // apiPost -> http Code 404 (catch)
        .catch((e) => {
          if (e.httpCode === 404) {
            if (bsLoop()) {
              notifyError(
                "Per abilitare il dipendente devi selezionare il punto vendita."
              );
              setStoredCode(null);
            } else {
              setValues({ ...values, value: true, type: "confirm" });
            }
          } else if (e.httpCode === 400) {
            notifyError("Il QR Code letto non corrisponde a nessun utente.");
            setStoredCode(null);
          } else {
            notifyError(getErrorMessageString(e, intl));
          }
        });
      setOpen(false);
    }
  };

  const handleEnable = (date) => {
    // API POST
    const code = { qrcode: storedCode, expiring_at: date };
    bmapi
      .createEmployeeAccess(code, selectedStoreId)
      .then(() => {
        notifySuccess(intl.formatMessage(account.saveConfirm));
        loadRegistrations();
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      });
    setValues({ ...values, value: false });
    setStoredCode(null);
  };

  const handleDisable = (id) => {
    // API DELETE
    bmapi
      .deleteEmployeeAccess(selectedStoreId, id)
      .then(() => {
        notifySuccess(intl.formatMessage(common.deleteElement));
        loadRegistrations();
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      });
    setValues({ ...values, value: false });
    setStoredCode(null);
  };

  const confirmAccess = (date) => {
    if (typeof date === "number" || typeof date === "string") {
      date = format(addMonths(new Date(), +date), "yyyy-MM-dd");
    } else {
      date = format(date, "yyyy-MM-dd");
    }
    handleEnable(date);
  };

  return (
    <Container maxWidth="sm">
      <Title>Autorizzazione accesso dipendenti</Title>
      {!isTenantManager && registrations.length > 0 && (
        <Typography variant="h6" gutterBottom>
          Registrazioni: {registrations.length}
        </Typography>
      )}
      {isTenantManager ? (
        <React.Fragment>
          <Card>
            <CardContent>
              <TextField
                value={
                  bsLoop()
                    ? "Tutti i negozi"
                    : businesses.find((b) => b.id === selectedStoreId).name
                }
                fullWidth
                margin="normal"
                disabled
              />
              <TextField
                name="email"
                label="Filtra per email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                margin="normal"
              />
              <FormControl fullWidth margin="normal">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={loadRegistrations}
                >
                  {intl.formatMessage(common.update)}
                </Button>
              </FormControl>
            </CardContent>
          </Card>
          <Box mt={2}>
            {registrations.length > 0 ? (
              <Typography variant="h6" gutterBottom>
                Registrazioni: {registrations.length}
              </Typography>
            ) : (
              <Card>
                <CardContent>Nessuna registrazione da mostrare.</CardContent>
              </Card>
            )}
          </Box>
          <Report
            registrations={registrations}
            businesses={businesses}
            handleDisable={handleDisable}
          />
        </React.Fragment>
      ) : (
        <Card>
          <CardContent>
            {registrations.sort(byDate).map((reg, i) => (
              <React.Fragment key={reg.id}>
                {i !== 0 && <Divider key={i} />}
                <List>
                  <ListItem>
                    <ListItemText
                      primary={format(
                        parseBmarkenDate(reg.created_at),
                        "dd/MM/yy"
                      )}
                      secondary="Data autor."
                    />
                    <ListItemText
                      primary={
                        !parseBmarkenDate(reg.expiring_at)
                          ? "Nessuna scadenza"
                          : format(
                              parseBmarkenDate(reg.expiring_at),
                              "dd/MM/yy"
                            )
                      }
                      secondary="Data scad."
                    />
                    <ListItemSecondaryAction>
                      {warningExpDate(reg.expiring_at) && (
                        <Warning exp={reg.expiring_at} />
                      )}
                      <IconButton
                        edge="end"
                        onClick={() => {
                          setShowDeleteAlert(true);
                          setRegIdToDelete(reg.id);
                        }}
                      >
                        <HighlightOff />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </React.Fragment>
            ))}
            {registrations.length === 0 && "Nessuna registrazione da mostrare."}
          </CardContent>
        </Card>
      )}

      <Confirm
        open={showDeleteAlert}
        onConfirm={() => {
          handleDisable(regIdToDelete);
          setShowDeleteAlert(false);
          setRegIdToDelete(false);
        }}
        onCancel={() => {
          setShowDeleteAlert(false);
          setRegIdToDelete(false);
        }}
        text={intl.formatMessage(confirm.deleteElement)}
      />

      {open && (
        <Dialog
          onClose={() => {
            setOpen(false), setStoredCode(null);
          }}
          open={open}
          fullWidth
        >
          <QrReader onError={handleError} onScan={handleScan} />
          <DialogActions margin="dense">
            <Button
              variant="contained"
              onClick={() => {
                setOpen(false);
                setStoredCode(null);
              }}
            >
              {intl.formatMessage(common.close)}
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <AccessDialog
        values={values}
        businesses={businesses}
        handleEnable={confirmAccess}
        handleDisable={handleDisable}
        reset={reset}
      />

      <FloatingActions>
        <Fab variant="extended" color="primary" onClick={() => setOpen(true)}>
          {intl.formatMessage({
            id: "common.scan",
            defaultMessage: "Scansiona",
          })}
          <CenterFocusStrong />
        </Fab>
      </FloatingActions>
    </Container>
  );
}
