import { defineMessages } from "react-intl";

export const subscriptions = defineMessages({
  accounts: {
    id: "subscriptions.accounts",
    defaultMessage: "{num, plural, one {# account} other {# account}}",
  },
  best: {
    id: "subscriptions.best",
    defaultMessage: "Consigliato",
  },
  buy: {
    id: "subscriptions.buy",
    defaultMessage: "Acquista",
  },
  campaigns: {
    id: "subscriptions.campaigns",
    defaultMessage: "{num, plural, one {# campagna} other {# campagne}}",
  },
  cardPerCampaign: {
    id: "subscriptions.cardPerCampaign",
    defaultMessage: "{num, number} card/campagna",
  },
  changePlan: {
    id: "subscriptions.changePlan",
    defaultMessage: "Cambia piano",
  },
  companyInfo: {
    id: "subscriptions.companyInfo",
    defaultMessage: "Informazioni società",
  },
  invoiceAddress: {
    id: "subscriptions.addressInvoice",
    defaultMessage: "Indirizzo fatturazione",
  },
  contactUs: {
    id: "subscriptions.contactUs",
    defaultMessage: "Contattaci",
  },
  couponsPerCampaign: {
    id: "subscriptions.couponsPerCampaign",
    defaultMessage: "{num, number} coupon/campagna",
  },
  couponsPerMonth: {
    id: "subscriptions.couponsPerMonth",
    defaultMessage: "{num, number} coupon/mese",
  },
  crm: {
    id: "subscriptions.crm",
    defaultMessage: "Funzionalità CRM",
  },
  enable: {
    id: "subscriptions.enable",
    defaultMessage: "Attiva",
  },
  instantWin: {
    id: "subscriptions.instantWin",
    defaultMessage: "Lotterie Instant Win",
  },
  limits: {
    id: "subscriptions.limits",
    defaultMessage: "Limiti",
  },
  loopCampaigns: {
    id: "subscriptions.loopCampaigns",
    defaultMessage: "Campagne di circuito",
  },
  loopCampaignsOn: {
    id: "subscriptions.loopCampaignsOn",
    defaultMessage:
      "Sarà possibile creare campagne (coupon, giftcard, fidelity, ...) valide in tutti i negozi aderenti collegati allo stesso circuito/gruppo",
  },
  loopCampaignsOff: {
    id: "subscriptions.loopCampaignsOff",
    defaultMessage:
      "Le campagne create (coupon, giftcard, fidelity, ...) saranno valide solo a livello di singolo negozio",
  },
  maxMonths: {
    id: "subscriptions.maxMonths",
    defaultMessage: "Durata massima {num, plural, one {# mese} other {# mesi}}",
  },
  managers: {
    id: "subscriptions.managers",
    defaultMessage: "Manager",
  },
  noSlots: {
    id: "subscriptions.noSlots",
    defaultMessage: "Slot esauriti",
  },
  notAvailableinTrial: {
    id: "subscriptions.notAvailableinTrial",
    defaultMessage: "Non disponibile per abbonamento trial",
  },
  paymentMonthly: {
    id: "subscriptions.paymentMonthly",
    defaultMessage: "Pagamento mensile",
  },
  paymentSemiannual: {
    id: "subscriptions.paymentSemiannual",
    defaultMessage: "Semestrale",
  },
  paymentAnnual: {
    id: "subscriptions.paymentAnnual",
    defaultMessage: "Annuale",
  },
  planName: {
    id: "subscriptions.planName",
    defaultMessage: "Piano {plan}",
  },
  planWithLimits: {
    id: "subscriptions.planWithLimits",
    defaultMessage: "Piano {plan} con questi limiti",
  },
  requestInfo: {
    id: "subscriptions.requestInfo",
    defaultMessage: "Richiedi informazioni",
  },
  roles: {
    id: "subscriptions.roles",
    defaultMessage: "Ruoli differenziati",
  },
  rolesOn: {
    id: "subscriptions.rolesOn",
    defaultMessage:
      "Sarà possibile creare Account (utenti) di servizio con ruoli differenziati rispetto alle funzionalità dell'applicazione (es: creazione campagne, erogazione coupon/card, validazione coupon/card, ...) corrispondenti alle diverse responsabilità del personale del negozio",
  },
  rolesOff: {
    id: "subscriptions.rolesOff",
    defaultMessage:
      "Gli Account (utenti) di servizio del negozio avranno tutti lo stesso ruolo in grado di utilizzare tutte le funzionalità dell'applicazione",
  },
  stores: {
    id: "subscriptions.stores",
    defaultMessage: "{num, plural, one {# negozio} other {# negozi}}",
  },
  subscription: {
    id: "subscriptions.subscription",
    defaultMessage: "Abbonamento",
  },
  title: {
    id: "subscriptions.title",
    defaultMessage: "Scegli il piano più adatto alla tua impresa",
  },
  trackVisits: {
    id: "subscriptions.trackVisits",
    defaultMessage: "Rilevamento visite",
  },
  unlimitedCampaings: {
    id: "subscriptions.unlimitedCampaings",
    defaultMessage: "Campagne illimitate",
  },
  unlimitedCampaingsDesc: {
    id: "subscriptions.unlimitedCampaingsDesc",
    defaultMessage:
      "Sarà possibile creare un numero illimitato di campagne loyalty/promozionali",
  },
  unlimitedStores: {
    id: "subscriptions.unlimitedStores",
    defaultMessage: "Negozi illimitati",
  },
  webAppConf: {
    id: "subscriptions.webAppConf",
    defaultMessage: "Configurazione web app",
  },
  configuration: {
    id: "subscription.configuration",
    defaultMessage: "Configurazione abbonamento",
  },
  completeConfigMessage: {
    id: "subscription.completeConfigMessage",
    defaultMessage:
      "Completa la configurazione fornendo il friendly URL. Puoi anche caricare il tuo logo e definire il colore di sfondo dell'applicazione. Più sotto puoi vedere un'anteprima delle tue pagine",
  },
  missingFriendlyUrlError: {
    id: "subscription.missingFriendlyUrlError",
    defaultMessage: "Specificare il friendly url",
  },
  missingCompanyNameError: {
    id: "subscription.missingCompanyNameError",
    defaultMessage: "Specificare il nome società",
  },
  missingVatNumFiscCodeError: {
    id: "subscription.missingVatNumFiscCodeError",
    defaultMessage: "Specificare la partita IVA o il codice fiscale",
  },
  missingInvoiceCodeEmailError: {
    id: "subscription.missingInvoiceCodeEmailError",
    defaultMessage: "Specificare il codice SDI o la mail PEC",
  },
  previewSubtitle: {
    id: "subscription.previewSubtitle",
    defaultMessage: "Ecco come appariranno le tue pagine",
  },
  updateSuccess: {
    id: "subscription.updateSuccess",
    defaultMessage: "Configurazione modificata con successo",
  },
  buyLogin: {
    id: "subscription.buyLogin",
    defaultMessage:
      "Per proseguire nell'acquisto accedi al tuo account o registrati premendo uno dei pulsanti qui sotto",
  },
  noBuyLogin: {
    id: "subscription.noBuyLogin",
    defaultMessage: "Accedi a BMarken per creare la tua soluzione loyalty",
  },
  buySignup: {
    id: "subscription.buySignup",
    defaultMessage: "Registrati per proseguire nell'acquisto",
  },
  noBuySignup: {
    id: "subscription.noBuySignup",
    defaultMessage: "Registrati per creare la tua soluzione loyalty",
  },
});
